<script setup>
import { useSlots } from 'vue';
import ReactiveSlot from './Partials/ReactiveSlot.vue';
import DefaultInner from './Partials/DefaultInner.vue';

const props = defineProps({
    type: {
        type: String,
        default: 'submit',
    },
    processing: {
        type: Boolean,
        default: false,
    },
    danger: {
        type: Boolean,
        default: false,
    },
    warning: {
        type: Boolean,
        default: false,
    },
    white: {
        type: Boolean,
        default: false,
    },
    orangeTransparent: {
        type: Boolean,
        default: false,
    },
    transparent: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    recentlySuccessful: {
        type: Boolean,
        default: false,
    },
    action: {
        type: String,
        default: '',
    },
    icon: {
        type: String,
        required: false,
    },
    iconRight: {
        type: Boolean,
        required: false,
    },
    text: {
        type: String,
        required: false,
    },
    hasErrors: {
        type: Boolean,
        required: false,
    },
});

const slots = useSlots();
</script>


<template>
    <button
        :type="type"
        :disabled="disabled || processing"
        name="action"
        :value="action"
        class="relative inline-flex items-center px-3 py-1 text-base leading-tight text-white uppercase transition-colors duration-300 border border-transparent rounded  disabled:bg-gray-500-400 disabled:pointer-events-none focus:outline-none focus:ring-2 focus:ring-offset-2"
        :class="{
            'bg-orange hover:bg-orange focus:ring-orange text-white text-[10px]':
                !warning && !white && !transparent && !orangeTransparent,
            'bg-red hover:bg-red-700 focus:ring-red text-white font-bold ': danger,
            'bg-white hover:bg-white focus:ring-green-500 text-black border-gray-800': white,
            'bg-white hover:bg-white text-orange border-orange text-base min-h-10': orangeTransparent,
            'bg-transparent hover:bg-white/10 focus:ring-green-500 text-green-500 border-green-500 border': transparent,
            'disabled:bg-gray-500-400': disabled,
        }"
    >
        <ReactiveSlot :processing="processing" :recentlySuccessful="recentlySuccessful">
            <DefaultInner v-if="!slots.default" :icon="icon" :iconRight="iconRight" :text="text" />

            <slot></slot>
        </ReactiveSlot>
    </button>
</template>

